import React from 'react';
import './login-header.scss';

import { SmallLanguageSwitcher, TranslatableText, TranslatedBlock } from './language';
import HemlibraLogoEN from '../images/Hemlibra_logo_white_EN.png';
import HemlibraLogoFR from '../images/Hemlibra_logo_white_FR.png';
import RocheLogo from '../images/Roche_logo_white.png';

export default function LoginHeader() {
  return (
    <header className="login-topbar">
      <div className="container">
        <div className="logo-container">
          <TranslatedBlock language="english">
            <img src={HemlibraLogoEN} alt="HEMLIBRA logo" className={'logo-img'} />
          </TranslatedBlock>
          <TranslatedBlock language="french">
            <img src={HemlibraLogoFR} alt="HEMLIBRA logo" className={'logo-img'} />
          </TranslatedBlock>
        </div>
        <div className="roche-logo-container">
          <SmallLanguageSwitcher />
          <img src={RocheLogo} alt="Roche logo" className={'roche-logo-img'} />
        </div>
        <h1 className="header-normal">
          <TranslatableText
            dictionary={{
              english: 'Welcome to HEMLIBRA.ca',
              french: 'Bienvenue sur le site HEMLIBRA.ca!',
            }}
          />
        </h1>
      </div>
    </header>
  );
}
