export const dinHelp = {
  headerEN: 'DIN information',
  headerFR: 'Informations sur le DIN',
  bodyEN: 'The DIN is the 8-digit number appearing in the top right corner of the box your HEMLIBRA came in.',
  bodyFR: 'Le DIN est le nombre à huit chiffres qui figure dans le coin supérieur droit de la boîte de HEMLIBRA.',
  closeEN: 'Close',
  closeFR: 'Fermer',
};

export const hcpInfo = {
  headerEN: 'For Healthcare Professionals',
  headerFR: 'Pour les professionnels de la santé',
  bodyEN:
    'We’ve moved! You can now access HEMLIBRA information, tools to use in your practice, and resources by visiting ',
  bodyFR: `Nous avons déménagé! Vous pouvez désormais accéder à\u00A0l’information sur HEMLIBRA, aux outils pour votre pratique et aux ressources à partir de `,
  closeEN: 'Visit RocheProPlus Now',
  closeFR: 'Accès à RocheProPlus',
};
