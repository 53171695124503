import React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { TranslatableText } from './language';

export default ({ isOpen, toggle, content }) => (
  <Modal isOpen={isOpen} toggle={toggle} content={content} className={'dinHelpModal'} labelledBy="din-modal-header">
    <ModalHeader toggle={toggle} tag="h3" id="din-modal-header">
      <TranslatableText
        dictionary={{
          english: content.headerEN,
          french: content.headerFR,
        }}
      />
    </ModalHeader>
    <ModalBody>
      <TranslatableText
        dictionary={{
          english: content.bodyEN,
          french: content.bodyFR,
        }}
      />
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={toggle}>
        <TranslatableText
          dictionary={{
            english: content.closeEN,
            french: content.closeFR,
          }}
        />
      </Button>
    </ModalFooter>
  </Modal>
);
