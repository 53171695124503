import React from 'react';

import { Button } from 'reactstrap';
import { TranslatableText, TranslatedBlock } from './language';

class PatientFormSection extends React.Component {
  state = {
    open: false,
    dinNumber: '',
    toVideo: false,
    error: '',
  };

  toggleHeader = () => {
    this.setState({ open: !this.state.open });
  };

  updateInputValue = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  VALID_DIN_NUMBERS = ['02479656', '02479664', '02479621', '02479648'];

  handlePatientLogin = event => {
    event.preventDefault();
    this.setState({ error: '' });
    if (this.state.dinNumber.length > 0) {
      if (this.VALID_DIN_NUMBERS.includes(this.state.dinNumber)) {
        window.location.replace('./patient/');
      } else {
        this.setState({ error: 'din-number-error' });
      }
    } else {
      this.setState({ error: 'din-number-empty' });
    }
  };

  render() {
    return this.props.children({
      open: this.state.open,
      toggleHeader: this.toggleHeader,
      handlePatientLogin: this.handlePatientLogin,
      updateInputValue: this.updateInputValue,
      error: this.state.error,
    });
  }
}

export const PatientLoginForm = ({ toggleDinModal, fullWidth, error }) => {
  if (fullWidth) {
    var patientFormSectionStyle = { width: '100%' };
  }
  return (
    <PatientFormSection>
      {({ open, toggleHeader, handlePatientLogin, updateInputValue, error }) => (
        <div className="login-patient login-container" style={patientFormSectionStyle}>
          <button className="login-container-header" onClick={toggleHeader}>
            <h2 className="header-small">
              <TranslatableText
                dictionary={{
                  english: 'For patients',
                  french: 'Patients',
                }}
              />
            </h2>
          </button>
          <div className="login-container-content" style={{ display: open && 'block' }}>
            <TranslatedBlock language="english">
              <p>
                In order to access this Website please enter the HEMLIBRA<sup>®</sup> (emicizumab injection) DIN (Drug
                Identification Number) in the box below.
              </p>
            </TranslatedBlock>
            <TranslatedBlock language="french">
              <p>
                Pour accéder à ce site Web, veuillez entrer le DIN (numéro d’identification du médicament) de HEMLIBRA
                <sup>®</sup> (émicizumab injectable) dans la case ci-dessous.
              </p>
            </TranslatedBlock>
            <div className="login-form-container">
              <form autoComplete="off">
                <div className="form-group xs-pt-30">
                  <label htmlFor="din-number">
                    <TranslatableText
                      dictionary={{
                        english: 'DIN Number',
                        french: 'DIN :',
                      }}
                    />
                  </label>
                  <br />
                  <TranslatedBlock language="english">
                    <input
                      id="din-number"
                      name="dinNumber"
                      className={error.startsWith('din-number') ? 'form-control error' : 'form-control'}
                      type="text"
                      placeholder="Enter number"
                      onChange={updateInputValue}
                    />
                  </TranslatedBlock>
                  <TranslatedBlock language="french">
                    <input
                      id="din-number"
                      name="dinNumber"
                      className={error.startsWith('din-number') ? 'form-control error' : 'form-control'}
                      type="text"
                      placeholder="Entrer le numéro"
                      onChange={updateInputValue}
                    />
                  </TranslatedBlock>

                  <span id="din-number-error" hidden={error !== 'din-number-error'} className="text-danger">
                    <TranslatableText
                      dictionary={{
                        english: 'Wrong DIN number',
                        french: 'Mauvais numéro',
                      }}
                    />
                  </span>
                  <span id="din-number-empty" hidden={error !== 'din-number-empty'} className="text-danger">
                    <TranslatableText
                      dictionary={{
                        english: 'Empty DIN number',
                        french: 'Entrer le numéro DIN',
                      }}
                    />
                  </span>
                </div>
                <div className="form-group xs-pt-15">
                  <TranslatedBlock language="english">
                    <input
                      type="submit"
                      id="patientLogin"
                      className="btn btn-darkblue btn-block btn-login"
                      value="LOGIN"
                      onClick={handlePatientLogin}
                    />
                  </TranslatedBlock>
                  <TranslatedBlock language="french">
                    <input
                      type="submit"
                      id="patientLogin"
                      className="btn btn-darkblue btn-block btn-login"
                      value="OUVRIR UNE SESSION"
                      onClick={handlePatientLogin}
                    />
                  </TranslatedBlock>
                </div>
              </form>
              <p className="text-center xs-pt-15">
                <Button className="blue-link" color={'link'} onClick={toggleDinModal}>
                  <TranslatableText
                    dictionary={{
                      english: 'Need help finding your DIN?',
                      french: 'Vous n’arrivez pas à trouver le DIN?',
                    }}
                  />
                </Button>
              </p>
            </div>
          </div>
        </div>
      )}
    </PatientFormSection>
  );
};
