import React from 'react';

import { TranslatableText, TranslatedBlock } from './language';
import Popup from './popup.js';
import { hcpInfo } from '../dictionary/dictionary.js';

class HcpFormSection extends React.Component {
  state = {
    open: false,
    hcpType: '',
    province: '',
    plnumber: '',
    toVideo: false,
    error: '',
    isPopupOpen: false,
  };

  toggleHeader = () => {
    this.setState({ open: !this.state.open });
  };

  togglePopup = () => {
    this.setState({ isPopupOpen: !this.state.isPopupOpen });
  };

  updateInputValue = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  PROVINCES = {
    ALBERTA: 'Alberta',
    BRITISH_COLUMBIA: 'British Columbia',
    MANITOBA: 'Manitoba',
    NEW_BRUNSWICK: 'New Brunswick',
    NEWFOUNDLAND: 'Newfoundland',
    NORTHWEST_TERRITORIES: 'Northwest Territories',
    NOVA_SCOTIA: 'Nova Scotia',
    NUNAVUT: 'Nunavut',
    ONTARIO: 'Ontario',
    QUEBEC: 'Quebec',
    PRINCE_EDWARD_ISLAND: 'Prince Edward Island',
    SASKATCHEWAN: 'Saskatchewan',
    YUKON: 'Yukon',
  };

  HCPTYPES = {
    NURSE: 'nurse',
    PHYSICIAN: 'physician',
    PHARMACIST: 'pharmacist',
  };

  numberOccurence = string => {
    var array = string.split('');
    var numberOccurences = 0;
    for (var i = 0; i < array.length; i++) {
      if (!isNaN(array[i])) {
        numberOccurences++;
      }
    }
    return numberOccurences;
  };
  isLicenseOk = (province, hcpType, license) => {
    var licenseOk = false;
    var len = 0;
    var numbers = 0;
    var regex = /^[a-zA-Z]*$/;

    switch (province) {
      case this.PROVINCES.ALBERTA:
        switch (hcpType) {
          case this.HCPTYPES.NURSE:
            len = license.length;
            numbers = this.numberOccurence(license);
            if (
              (len === 1 && numbers === 1) ||
              (len === 2 && numbers === 2) ||
              (len === 3 && numbers === 3) ||
              (len === 4 && numbers === 4) ||
              (len === 5 && numbers === 5) ||
              (len === 6 && numbers === 6)
            ) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHYSICIAN:
            len = license.length;
            if (
              (len === 5 && !isNaN(license)) ||
              (len === 6 &&
                this.numberOccurence(license) === 5 &&
                (license.includes('E') || license.includes('S') || license.includes('C'))) ||
              this.numberOccurence(license) === 6
            ) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHARMACIST:
            len = license.length;
            if ((len === 4 && !isNaN(license)) || (len === 5 && !isNaN(license))) {
              licenseOk = true;
            }
            break;
          default:
            break;
        }
        break;
      case this.PROVINCES.BRITISH_COLUMBIA:
        switch (hcpType) {
          case this.HCPTYPES.NURSE:
            len = license.length;
            numbers = this.numberOccurence(license);
            if ((len === 5 && numbers === 5) || (len === 6 && !isNaN(license))) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHYSICIAN:
            len = license.length;
            if (len === 5 && !isNaN(license)) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHARMACIST:
            len = license.length;
            if (len === 5 && !isNaN(license)) {
              licenseOk = true;
            }
            break;
          default:
            break;
        }
        break;
      case this.PROVINCES.MANITOBA:
        switch (hcpType) {
          case this.HCPTYPES.NURSE:
            len = license.length;
            if (len === 6 && this.numberOccurence(license) === 6) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHYSICIAN:
            len = license.length;
            if (len === 5 && !isNaN(license)) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHARMACIST:
            len = license.length;
            if (len === 5 && !isNaN(license)) {
              licenseOk = true;
            }
            break;
          default:
            break;
        }
        break;
      case this.PROVINCES.NEW_BRUNSWICK:
        switch (hcpType) {
          case this.HCPTYPES.NURSE:
            len = license.length;
            if (len === 6 && this.numberOccurence(license) === 6) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHYSICIAN:
            len = license.length;
            if ((len === 4 || len === 5 || len === 7) && !isNaN(license)) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHARMACIST:
            len = license.length;
            if (len === 4 && !isNaN(license)) {
              licenseOk = true;
            }
            break;
          default:
            break;
        }
        break;
      case this.PROVINCES.NEWFOUNDLAND:
        switch (hcpType) {
          case this.HCPTYPES.NURSE:
            len = license.length;
            if ((len === 4 || len === 5) && !isNaN(license)) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHYSICIAN:
            if (regex.test(license.substring(0, 1)) && !isNaN(license.slice(-5))) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHARMACIST:
            len = license.length;
            if ((len === 5 && this.numberOccurence(license) === 5) || (len === 6 && !isNaN(license))) {
              licenseOk = true;
            }
            break;
          default:
            break;
        }
        break;
      case this.PROVINCES.NORTHWEST_TERRITORIES:
        switch (hcpType) {
          case this.HCPTYPES.NURSE:
            len = license.length;
            if (len < 5 && !isNaN(license)) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHYSICIAN:
            len = license.length;
            if (
              (len === 9 && this.numberOccurence(license) === 9) ||
              (len === 8 && this.numberOccurence(license) === 8)
            ) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHARMACIST:
            len = license.length;
            if (
              (len === 9 && this.numberOccurence(license) === 9) ||
              (len === 8 && this.numberOccurence(license) === 8)
            ) {
              licenseOk = true;
            }
            break;
          default:
            break;
        }
        break;
      case this.PROVINCES.NOVA_SCOTIA:
        switch (hcpType) {
          case this.HCPTYPES.NURSE:
            len = license.length;
            if (
              (len === 4 && this.numberOccurence(license) === 4) ||
              (len === 5 && this.numberOccurence(license) === 5)
            ) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHYSICIAN:
            len = license.length;
            if (
              (len === 6 && !isNaN(license)) ||
              (len && regex.test(license.substring(0, 1)) && isNaN(license.slice(-6)))
            ) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHARMACIST:
            len = license.length;
            if (len > 1 && len < 5 && !isNaN(license)) {
              licenseOk = true;
            }
            break;
          default:
            break;
        }
        break;
      case this.PROVINCES.NUNAVUT:
        switch (hcpType) {
          case this.HCPTYPES.NURSE:
            len = license.length;
            if (len < 5 && !isNaN(license)) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHARMACIST:
            len = license.length;
            if (len === 8 && !isNaN(license)) {
              licenseOk = true;
            }
            break;
          default:
            break;
        }
        break;
      case this.PROVINCES.ONTARIO:
        switch (hcpType) {
          case this.HCPTYPES.NURSE:
            len = license.length;
            if (
              (len === 7 && regex.test(license.substring(0, 2)) && this.numberOccurence(license) === 5) ||
              (len === 8 && regex.test(license.substring(0, 2)) && this.numberOccurence(license) === 6) ||
              (len === 7 && !isNaN(license))
            ) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHYSICIAN:
            len = license.length;
            if ((len === 5 || len === 6) && !isNaN(license)) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHARMACIST:
            len = license.length;
            if (
              (len === 3 && this.numberOccurence(license) === 3) ||
              (len === 4 && this.numberOccurence(license) === 4) ||
              (len === 6 && this.numberOccurence(license) === 6) ||
              (len === 5 && this.numberOccurence(license) === 5)
            ) {
              licenseOk = true;
            }
            break;
          default:
            break;
        }
        break;
      case this.PROVINCES.QUEBEC:
        switch (hcpType) {
          case this.HCPTYPES.NURSE:
            len = license.length;
            if ((len === 6 || len === 7) && !isNaN(license)) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHYSICIAN:
            len = license.length;
            if ((len === 5 || len === 6) && !isNaN(license)) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHARMACIST:
            len = license.length;
            if (len === 6 && !isNaN(license)) {
              licenseOk = true;
            }
            if (!isNaN(license) && ((+license > 1000 && +license < 4999) || (+license > 40000 && +license < 99327))) {
              licenseOk = true;
            }
            break;
          default:
            break;
        }
        break;
      case this.PROVINCES.PRINCE_EDWARD_ISLAND:
        switch (hcpType) {
          case this.HCPTYPES.NURSE:
            len = license.length;
            if (len === 4 && this.numberOccurence(license) === 4) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHYSICIAN:
            len = license.length;
            if (
              ((len === 3 || len === 4) && !isNaN(license)) ||
              (len === 5 && regex.test(license.substring(0, 1)) && !isNaN(license.slice(-4)))
            ) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHARMACIST:
            len = license.length;
            if ((len === 5 && !isNaN(license)) || (len === 4 && !isNaN(license))) {
              licenseOk = true;
            }
            break;
          default:
            break;
        }
        break;
      case this.PROVINCES.SASKATCHEWAN:
        switch (hcpType) {
          case this.HCPTYPES.NURSE:
            len = license.length;
            if (
              (len === 4 && this.numberOccurence(license) === 4) ||
              (len === 5 && this.numberOccurence(license) === 5) ||
              (len === 7 && this.numberOccurence(license) === 7) ||
              (len === 10 && this.numberOccurence(license) === 10)
            ) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHYSICIAN:
            len = license.length;
            if ((len === 6 && this.numberOccurence(license) === 6) || (len === 4 && !isNaN(license))) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHARMACIST:
            len = license.length;
            if (len === 5 && regex.test(license.substring(0, 1)) && !isNaN(license.slice(-4))) {
              licenseOk = true;
            }
            break;
          default:
            break;
        }
        break;
      case this.PROVINCES.YUKON:
        switch (hcpType) {
          case this.HCPTYPES.NURSE:
            len = license.length;
            if ((len === 4 && this.numberOccurence(license) === 4) || (len === 3 && !isNaN(license))) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHYSICIAN:
            len = license.length;
            if (len === 8 && !isNaN(license)) {
              licenseOk = true;
            }
            break;
          case this.HCPTYPES.PHARMACIST:
            len = license.length;
            if (len === 8 && !isNaN(license)) {
              licenseOk = true;
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }

    return licenseOk;
  };

  handleHCPLogin = event => {
    event.preventDefault();
    this.setState({ error: '' });
    if (this.state.plnumber.length > 0) {
      var plnumberWihoutZeros = this.state.plnumber.replace(/^0+/, '');
      var result =
        this.isLicenseOk(this.state.province, this.state.hcpType, this.state.plnumber) ||
        this.isLicenseOk(this.state.province, this.state.hcpType, plnumberWihoutZeros);
      if (result) {
        window.location.replace('./hcp/');
      } else {
        this.setState({ error: 'license-number-wrong' });
      }
    } else {
      this.setState({ error: 'license-number-empty' });
    }
  };

  render() {
    return this.props.children({
      open: this.state.open,
      toggleHeader: this.toggleHeader,
      handleHCPLogin: this.handleHCPLogin,
      updateInputValue: this.updateInputValue,
      error: this.state.error,
      isPopupOpen: this.state.isPopupOpen,
      togglePopup: this.togglePopup,
    });
  }
}

export const HcpLoginForm = ({ fullWidth }) => {
  if (fullWidth) {
    var patientFormSectionStyle = { width: '100%' };
  }
  return (
    <HcpFormSection>
      {({ open, toggleHeader, handleHCPLogin, isPopupOpen, togglePopup }) => (
        <div className="login-professional login-container" style={patientFormSectionStyle}>
          <button className="login-container-header" onClick={toggleHeader}>
            <h2 className="header-small">
              <TranslatableText
                dictionary={{
                  english: hcpInfo.headerEN,
                  french: hcpInfo.headerFR,
                }}
              />
            </h2>
          </button>
          <div className="login-container-content" style={{ display: open && 'block' }}>
            <TranslatedBlock language="english">
              <p>
                {hcpInfo.bodyEN}
                <a
                  href="https://rocheproplus.ca/en/products-resources/hemlibra/about.protected.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  rocheproplus.ca
                </a>
              </p>
            </TranslatedBlock>
            <TranslatedBlock language="french">
              <p>
                {hcpInfo.bodyFR}
                <a
                  href="https://rocheproplus.ca/fr/products-resources/hemlibra/about.protected.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  rocheproplus.ca
                </a>
              </p>
            </TranslatedBlock>
            <div className="form-group xs-pt-10">
              <TranslatedBlock language="english">
                <a
                  href="https://rocheproplus.ca/en/products-resources/hemlibra/about.protected.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <input
                    type="submit"
                    id="hcpLogin"
                    className="btn btn-darkblue btn-block btn-login"
                    value={hcpInfo.closeEN}
                  />
                </a>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <a
                  href="https://rocheproplus.ca/fr/products-resources/hemlibra/about.protected.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <input
                    type="submit"
                    id="hcpLogin"
                    className="btn btn-darkblue btn-block btn-login"
                    value={hcpInfo.closeFR}
                  />
                </a>
              </TranslatedBlock>
            </div>
          </div>
          {isPopupOpen ? (
            <>
              <Popup isOpen={isPopupOpen} toggle={togglePopup} content={hcpInfo} />
            </>
          ) : (
            ''
          )}
        </div>
      )}
    </HcpFormSection>
  );
};
